import React from 'react';
import './Title.css';

const Title = () => {
    return (
        <h1 className="title">
            Online ABI Encoding Service
        </h1>
    )
}

export default Title;